import request from '@/utils/request'

export interface SetupFile {
    fileName: string,
    fileUrl: string
}

export const getSetupFiles = () =>
  request({
    url: `/v1/upgradeTestServe/setupFileList`,
    method: 'GET',
  })