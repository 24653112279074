





























import { Component, Vue } from "vue-property-decorator";
import CardBox from "@/components/card-box/index.vue";
import * as UpgradeApi from "@/api/upgrade";
import * as _ from "lodash";

@Component({
  name: "Dashboard",
  components: {
    CardBox,
  },
})
export default class extends Vue {
  private setupFiles: UpgradeApi.SetupFile[] = [];
  private async __init() {
    this.setupFiles.splice(0, this.setupFiles.length);
    const { data } = await UpgradeApi.getSetupFiles();
    this.setupFiles = _.concat(this.setupFiles, data.items);
    console.log(JSON.stringify(data.items));
  }
  private isRedMark(a: string): boolean {
    const tips: string[] = ["AllInOne"];
    for (let i = 0; i < tips.length; i++) {
      if (a.includes(tips[i])) {
        return true;
        break;
      }
    }
    return false;
  }
  mounted() {
    this.__init();
  }
}
